<template>
  <v-card>
    <v-row no-gutters>
      <v-col cols="12" md="4">
        <div class="d-flex align-center justify-center h-100 gradient">
          <svg-icon icon="profile-background" class="pa-4" />
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <div class="pa-4 pa-md-6">
          <div v-if="loading" class="text-center pt-16">
            <div class="mb-5">{{ $t('account.getting_info') }}</div>
            <v-progress-circular indeterminate color="primary" />
          </div>
          <form v-if="!loading">
            <v-row>
              <v-col cols="12" md="6">
                <BaseFormGroup :name="$t('form.company_name')" :validator="$v.form.company_name">
                  <template slot-scope="{ attrs }">
                    <BaseLabel :title="$t('form.company_name')" />
                    <v-text-field
                      v-model="form.company_name"
                      v-bind="attrs"
                      :placeholder="$t('form.enter_company_name')"
                      outlined
                      dense
                      tabindex="1"
                      class="rounded-lg"
                      hide-details="auto"
                      @input="$v.form.company_name.$touch()"
                      @blur="$v.form.company_name.$touch()"
                    >
                      <template #append-outer> </template>
                    </v-text-field>
                  </template>
                </BaseFormGroup>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center mt-md-8" v-if="hasVerified">
                  <svg-icon icon="check" class="mr-2" style="width: 20px; height: 20px" /> Company info has been verified.
                </div>
                <Popper
                  :disabled="!popperShow"
                  :force-show="popperShow"
                  :trigger="popperTrigger"
                  :options="{ placement: 'top' }"
                  transition="fade"
                  enter-active-class="fade-enter-active"
                  leave-active-class="fade-leave-active"
                >
                  <v-btn
                    slot="reference"
                    v-if="!hasVerified"
                    @click="showVerifyForm"
                    class="indigo white--text no-transform mt-md-7"
                    elevation="0"
                  >
                    {{ $t('content.verify_my_company_title') }}
                  </v-btn>
                  <div class="popper extra-popover without-arrow">
                    <h1>Button Verify My Company</h1>
                    <p class="pa-5 mb-0">
                      This is only needed if you need to request access <br />
                      to Master Account which will enable you to view ALL features/ services of your organization. <br />
                      Currently you will have access to features/ services <br />
                      that were sent to/ shared with you. Upon the verification of your company, you can go to your <br />
                      Account Setting and request to upgrade to Master Account.
                      <v-checkbox @change="hideInfo" label="Don't show this again" hide-details />
                    </p>
                  </div>
                </Popper>
              </v-col>
              <v-col cols="12">
                <v-divider style="border-style: dashed" />
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <BaseFormGroup :name="$t('form.first_name')" :validator="$v.form.first_name" noGutter>
                  <template slot-scope="{ attrs }">
                    <BaseLabel :title="$t('form.first_name')" />
                    <v-text-field
                      v-model="form.first_name"
                      v-bind="attrs"
                      :placeholder="$t('form.enter_first_name')"
                      outlined
                      dense
                      tabindex="2"
                      :counter="30"
                      maxlength="30"
                      class="rounded-lg"
                      hide-details="auto"
                      @input="$v.form.first_name.$touch()"
                      @blur="$v.form.first_name.$touch()"
                    />
                  </template>
                </BaseFormGroup>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <BaseFormGroup :name="$t('form.last_name')" :validator="$v.form.last_name" noGutter>
                  <template slot-scope="{ attrs }">
                    <BaseLabel :title="$t('form.last_name')" />
                    <v-text-field
                      v-model="form.last_name"
                      v-bind="attrs"
                      :placeholder="$t('form.enter_last_name')"
                      outlined
                      dense
                      class="rounded-lg"
                      hide-details="auto"
                      @input="$v.form.last_name.$touch()"
                      @blur="$v.form.last_name.$touch()"
                    />
                  </template>
                </BaseFormGroup>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <BaseFormGroup :name="$t('form.address')" :validator="$v.form.address">
                  <template slot-scope="{ attrs }">
                    <BaseLabel :title="$t('form.address')" />
                    <v-text-field
                      v-model="form.address"
                      v-bind="attrs"
                      :placeholder="$t('form.enter_address')"
                      outlined
                      dense
                      tabindex="4"
                      class="rounded-lg"
                      hide-details="auto"
                      @input="$v.form.address.$touch()"
                      @blur="$v.form.address.$touch()"
                    />
                  </template>
                </BaseFormGroup>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <BaseFormGroup :name="$t('form.phone_number')" :validator="$v.form.phone_number">
                  <template slot-scope="{ attrs }">
                    <BaseLabel :title="$t('form.phone_number')" />
                    <v-text-field
                      v-model="form.phone_number"
                      v-bind="attrs"
                      :placeholder="$t('form.enter_phone_number')"
                      outlined
                      dense
                      tabindex="5"
                      maxlength="30"
                      class="rounded-lg"
                      @input="$v.form.phone_number.$touch()"
                      @blur="$v.form.phone_number.$touch()"
                    />
                  </template>
                </BaseFormGroup>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-btn
                :loading="buttonLoading"
                :disabled="buttonDisabledStatus"
                class="mr-3 indigo white--text no-transform "
                @click="updateProfile"
                elevation="0"
              >
                {{ $t('account.update_profile') }}
              </v-btn>
            </div>
          </form>
        </div>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="infoDialog" max-width="600">
      <v-card>
        <div class="pt-10">
          <v-img src="../../assets/images/success-icon.png" class="dialog-image" />
        </div>
        <div class="text-center text-h5 font-weight-bold pt-3 dialog-title">
          {{ $t('form.new_password_top') }}
        </div>
        <div class="text-center pb-8 pt-2 dialog-body">
          {{ $t(sourceDialog === 'account' ? 'form.change_account_profile' : 'form.change_company_verified') }}
        </div>
        <div class="text-center pb-8">
          <v-btn color="primary no-transform dialog-button" @click="onHideDialog">
            {{ $t('content.ok') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="verifyDialog" max-width="500">
      <v-card>
        <div class="pa-8">
          <div class="text-h5 font-weight-bold pt-3 dialog-title">
            {{ $t('content.verify_my_company_title') }}
          </div>
          <div class="pb-8 pt-2 dialog-body">
            {{ $t('content.verify_my_company_desc') }}
          </div>
          <BaseFormGroup :name="$t('form.office')" :validator="$v.verify.officeId">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('form.office')" />
              <v-autocomplete
                v-model="verify.officeId"
                v-bind="attrs"
                :placeholder="$t('form.office')"
                :items="offices"
                outlined
                dense
                class="rounded-lg"
                item-value="id"
                item-text="name"
                @input="$v.verify.officeId.$touch()"
                @blur="$v.verify.officeId.$touch()"
              />
            </template>
          </BaseFormGroup>
          <BaseFormGroup :name="$t('form.service')" :validator="$v.verify.contactId">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('form.service')" />
              <v-autocomplete
                v-model="verify.contactId"
                v-bind="attrs"
                :placeholder="$t('form.service')"
                :items="services"
                outlined
                dense
                class="rounded-lg"
                item-value="id"
                item-text="business_segment_name"
                @input="$v.verify.contactId.$touch()"
                @blur="$v.verify.contactId.$touch()"
              />
            </template>
          </BaseFormGroup>
          <div class="text-center">
            <v-btn color="primary" class="dialog-button font-weight-bold mr-3" outlined @click="onCancelVerify">
              {{ $t('content.cancel') }}
            </v-btn>

            <v-btn color="primary" class=" dialog-button font-weight-bold" @click="onVerify" :loading="verifyButtonLoading">
              {{ $t('content.submit') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import phoneNumber from '@/validators/phoneNumber';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'AccountProfile',
  validations: {
    form: {
      company_name: { required, minLength: minLength(3) },
      phone_number: { required, phoneNumber, minLength: minLength(8) },
      address: { required, minLength: minLength(8) },
      first_name: { required, maxLength: maxLength(30) },
      last_name: { required, maxLength: maxLength(30) },
    },
    verify: {
      officeId: { required },
      contactId: { required },
    },
  },
  props: {
    isShowSidebar: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    form: {
      first_name: '',
      last_name: '',
      company_name: '',
      phone_number: '',
      address: '',
      is_subscribed: false,
    },
    verify: {
      officeId: null,
      contactId: null,
    },
    sourceDialog: '',
    loading: true,
    buttonLoading: false,
    infoDialog: false,
    verifyDialog: false,
    hasVerified: false,
    verifyButtonLoading: false,
    hasReadInfo: Boolean(localStorage.getItem('hasReadInfoMaster')),
    popperHide: false,
  }),
  computed: {
    ...mapGetters({
      offices: 'Account/offices',
      services: 'Account/services',
      user: 'Auth/user',
    }),
    buttonDisabledStatus() {
      return !this.$v.form.$anyDirty;
    },
    popperShow() {
      if (this.popperHide) {
        return false;
      }
      let status = !this.hasReadInfo && !this.hasVerified;

      return !['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? status : status && !this.isShowSidebar;
    },
    popperTrigger() {
      return !['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? 'click' : 'hover';
    },
  },
  watch: {
    'verify.officeId'(val) {
      this.fetchServices(val);
    },
  },
  methods: {
    getAccountProfile() {
      const profileId = this.user?.customer_user_profile_id;
      const emailId = this.user?.email_id;

      this.$store
        .dispatch('Account/getProfile', profileId)
        .then(res => {
          this.form.address = res.address;
          this.form.phone_number = res.phone_number;
          this.form.company_name = res.company_name;
          this.hasVerified = res.is_portal_info_verified;

          this.$store
            .dispatch('Auth/getAccountInfo')
            .then(res => {
              this.form.first_name = res.first_name;
              this.form.last_name = res.last_name;
              this.$store
                .dispatch('Auth/getSubscribed', emailId)
                .then(res => {
                  this.form.is_subscribed = res.is_subscribed;
                })
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false));
            })
            .catch(err => this.$toast.error(err.message));
        })
        .catch(err => this.$toast.error(err.message));
    },
    updateProfile() {
      this.$v.$touch();
      if (this.$v.form.$invalid) return false;

      this.buttonLoading = true;
      const payload = {
        id: this.user?.customer_user_profile_id,
        form: this.form,
      };

      this.$store
        .dispatch('Account/patchAccountProfile', payload)
        .then(() => {
          this.$store
            .dispatch('Account/patchMe', this.form)
            .then(() => {
              this.$store.dispatch('Auth/getAccountInfo', this.form);
              this.infoDialog = true;
              this.sourceDialog = 'account';
            })
            .catch(err => this.$toast.error(err.message));
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.buttonLoading = false));
    },
    onHideDialog() {
      this.infoDialog = false;
      this.sourceDialog = false;
      this.$v.$reset();
    },
    fetchOffices() {
      this.$store.dispatch('Account/fetchOffices');
    },
    fetchServices(id) {
      this.$store.dispatch('Account/fetchServices', id).then(() => {
        this.verify.contactId = null;
      });
    },
    onVerify() {
      this.$v.verify.$touch();
      if (this.$v.verify.$invalid) return false;

      this.verifyButtonLoading = true;
      this.$store
        .dispatch('Account/verifyMyCompany', this.verify.contactId)
        .then(() => {
          this.onSuccessVerified();
        })
        .catch(err => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.verifyButtonLoading = false;
        });
    },
    onCancelVerify() {
      this.popperHide = false;
      this.infoDialog = false;
      this.verifyDialog = false;
    },
    onSuccessVerified() {
      this.popperHide = false;
      this.infoDialog = true;
      this.verifyDialog = false;
    },
    showVerifyForm() {
      this.popperHide = true;
      this.verifyDialog = true;
    },
    hideInfo(val) {
      if (val) {
        this.hasReadInfo = true;
        localStorage.setItem('hasReadInfoMaster', 1);
      }
    },
  },
  created() {
    this.getAccountProfile();
    this.fetchOffices();
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-image {
    margin-left: auto;
    margin-right: auto;
    width: 120px;
  }
  &-title {
    color: #16325c;
  }
  &-body {
    font-size: 15px;
    color: #16325ccc;
  }
  &-button {
    max-width: 600px;
  }
}

.gradient {
  background: rgb(0, 96, 175);
  background: linear-gradient(
    0deg,
    rgba(0, 96, 175, 0.6001751042213761) 0%,
    rgba(176, 210, 237, 0.569362779291404) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
